import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _a13e2f6a = () => interopDefault(import('../client/pages/cart/index.vue' /* webpackChunkName: "" */))
const _c7543736 = () => interopDefault(import('../client/pages/products/no-minimum/_.vue' /* webpackChunkName: "" */))
const _f239b30e = () => interopDefault(import('../client/pages/products/closeout/_.vue' /* webpackChunkName: "" */))
const _0932f4f8 = () => interopDefault(import('../client/pages/account/index.vue' /* webpackChunkName: "pages/account/index" */))
const _5b5924ea = () => interopDefault(import('../client/pages/cartitemsadded/index.vue' /* webpackChunkName: "pages/cartitemsadded/index" */))
const _25d07db6 = () => interopDefault(import('../client/pages/changepassword/index.vue' /* webpackChunkName: "pages/changepassword/index" */))
const _5f52c8a7 = () => interopDefault(import('../client/pages/customer-artwork-proof/index.vue' /* webpackChunkName: "pages/customer-artwork-proof/index" */))
const _70c92740 = () => interopDefault(import('../client/pages/designs/index.vue' /* webpackChunkName: "pages/designs/index" */))
const _57a9ffc7 = () => interopDefault(import('../client/pages/facets/index.vue' /* webpackChunkName: "pages/facets/index" */))
const _b8c426bc = () => interopDefault(import('../client/pages/favorites/index.vue' /* webpackChunkName: "pages/favorites/index" */))
const _01ed7669 = () => interopDefault(import('../client/pages/forgotpassword/index.vue' /* webpackChunkName: "pages/forgotpassword/index" */))
const _6774c5f8 = () => interopDefault(import('../client/pages/health.vue' /* webpackChunkName: "pages/health" */))
const _d17cf5da = () => interopDefault(import('../client/pages/not-found/index.vue' /* webpackChunkName: "pages/not-found/index" */))
const _572dbf4f = () => interopDefault(import('../client/pages/proof/index.vue' /* webpackChunkName: "pages/proof/index" */))
const _de5b60e2 = () => interopDefault(import('../client/pages/proofitemsadded/index.vue' /* webpackChunkName: "pages/proofitemsadded/index" */))
const _c5ea0e72 = () => interopDefault(import('../client/pages/quoteitemsadded/index.vue' /* webpackChunkName: "pages/quoteitemsadded/index" */))
const _75594775 = () => interopDefault(import('../client/pages/resetpassword/index.vue' /* webpackChunkName: "pages/resetpassword/index" */))
const _59e17b15 = () => interopDefault(import('../client/pages/sampleitemsadded/index.vue' /* webpackChunkName: "pages/sampleitemsadded/index" */))
const _47e65e4d = () => interopDefault(import('../client/pages/signin/index.vue' /* webpackChunkName: "pages/signin/index" */))
const _22e9023c = () => interopDefault(import('../client/pages/signout/index.vue' /* webpackChunkName: "pages/signout/index" */))
const _8516831c = () => interopDefault(import('../client/pages/suppliers/index.vue' /* webpackChunkName: "pages/suppliers/index" */))
const _33d5c472 = () => interopDefault(import('../client/pages/testy/index.vue' /* webpackChunkName: "pages/testy/index" */))
const _5a15ef60 = () => interopDefault(import('../client/pages/version.vue' /* webpackChunkName: "pages/version" */))
const _52813fb7 = () => interopDefault(import('../client/pages/version-app/index.vue' /* webpackChunkName: "pages/version-app/index" */))
const _20ecdb4d = () => interopDefault(import('../client/pages/cat/GeneralProductView.vue' /* webpackChunkName: "pages/cat/GeneralProductView" */))
const _ca7ff24e = () => interopDefault(import('../client/pages/orders/products/index.vue' /* webpackChunkName: "pages/orders/products/index" */))
const _c0b0d03e = () => interopDefault(import('../client/pages/products/utils.js' /* webpackChunkName: "pages/products/utils" */))
const _b8a1ec7c = () => interopDefault(import('../client/pages/products/search/mixin.js' /* webpackChunkName: "pages/products/search/mixin" */))
const _0b1a4e3a = () => interopDefault(import('../client/pages/products/search/search.js' /* webpackChunkName: "pages/products/search/search" */))
const _43eea99c = () => interopDefault(import('../client/pages/sso/facebook/delete.vue' /* webpackChunkName: "pages/sso/facebook/delete" */))
const _d8deca3c = () => interopDefault(import('../client/pages/sso/punch-out/_source.vue' /* webpackChunkName: "pages/sso/punch-out/_source" */))
const _2cd2c4e0 = () => interopDefault(import('../client/pages/review/order/_orderId/product/_productId.vue' /* webpackChunkName: "pages/review/order/_orderId/product/_productId" */))
const _115cc9ea = () => interopDefault(import('../client/pages/products/search/_.vue' /* webpackChunkName: "pages/products/search/_" */))
const _d173903e = () => interopDefault(import('../client/pages/blog/cat/_.vue' /* webpackChunkName: "pages/blog/cat/_" */))
const _8389bcbe = () => interopDefault(import('../client/pages/approval/_orderId/index.vue' /* webpackChunkName: "pages/approval/_orderId/index" */))
const _7c788658 = () => interopDefault(import('../client/pages/artproof/_productSlug/index.vue' /* webpackChunkName: "pages/artproof/_productSlug/index" */))
const _47238699 = () => interopDefault(import('../client/pages/authors/_authorName/index.vue' /* webpackChunkName: "pages/authors/_authorName/index" */))
const _3a28ec1d = () => interopDefault(import('../client/pages/components/_componentId/index.vue' /* webpackChunkName: "pages/components/_componentId/index" */))
const _1fdfcd4c = () => interopDefault(import('../client/pages/configureproduct/_productSlug/index.vue' /* webpackChunkName: "pages/configureproduct/_productSlug/index" */))
const _2e8d8fdc = () => interopDefault(import('../client/pages/customer-artwork-proof/_id/index.vue' /* webpackChunkName: "pages/customer-artwork-proof/_id/index" */))
const _668ceb6a = () => interopDefault(import('../client/pages/designer/_productSlug/index.vue' /* webpackChunkName: "pages/designer/_productSlug/index" */))
const _4930b27b = () => interopDefault(import('../client/pages/designs/_slug/index.vue' /* webpackChunkName: "pages/designs/_slug/index" */))
const _a10e6c7c = () => interopDefault(import('../client/pages/facets/_slug/index.vue' /* webpackChunkName: "pages/facets/_slug/index" */))
const _c99c8d18 = () => interopDefault(import('../client/pages/largequote/_productSlug/index.vue' /* webpackChunkName: "pages/largequote/_productSlug/index" */))
const _9661e68a = () => interopDefault(import('../client/pages/orders/_id/index.vue' /* webpackChunkName: "pages/orders/_id/index" */))
const _3ad92712 = () => interopDefault(import('../client/pages/payments/_orderId/index.vue' /* webpackChunkName: "pages/payments/_orderId/index" */))
const _dd1bc92c = () => interopDefault(import('../client/pages/presentation/_orderId/index.vue' /* webpackChunkName: "pages/presentation/_orderId/index" */))
const _766c6562 = () => interopDefault(import('../client/pages/presentations/_id/index.vue' /* webpackChunkName: "pages/presentations/_id/index" */))
const _3948c4e4 = () => interopDefault(import('../client/pages/printinvoice/_orderId/index.vue' /* webpackChunkName: "pages/printinvoice/_orderId/index" */))
const _15c9058a = () => interopDefault(import('../client/pages/products/_slug/index.vue' /* webpackChunkName: "pages/products/_slug/index" */))
const _112eca8c = () => interopDefault(import('../client/pages/proof/_id/index.vue' /* webpackChunkName: "pages/proof/_id/index" */))
const _25a9f5b4 = () => interopDefault(import('../client/pages/quickquote/_productSlug/index.vue' /* webpackChunkName: "pages/quickquote/_productSlug/index" */))
const _a5d0ab9e = () => interopDefault(import('../client/pages/reorder/_id/index.vue' /* webpackChunkName: "pages/reorder/_id/index" */))
const _6dd9e1cb = () => interopDefault(import('../client/pages/sample/_productSlug/index.vue' /* webpackChunkName: "pages/sample/_productSlug/index" */))
const _78afb9c4 = () => interopDefault(import('../client/pages/sso/_service.vue' /* webpackChunkName: "pages/sso/_service" */))
const _69b31211 = () => interopDefault(import('../client/pages/cat/_slug/brand.vue' /* webpackChunkName: "pages/cat/_slug/brand" */))
const _9a73d4f4 = () => interopDefault(import('../client/pages/orders/_id/track-shipment/index.vue' /* webpackChunkName: "pages/orders/_id/track-shipment/index" */))
const _1eed88e4 = () => interopDefault(import('../client/pages/printinvoice/_orderId/short.vue' /* webpackChunkName: "pages/printinvoice/_orderId/short" */))
const _0898bc53 = () => interopDefault(import('../client/pages/products/_slug/reviews.vue' /* webpackChunkName: "pages/products/_slug/reviews" */))
const _51e49f7b = () => interopDefault(import('../client/pages/facets/_slug/_facet/index.vue' /* webpackChunkName: "pages/facets/_slug/_facet/index" */))
const _1f37ac24 = () => interopDefault(import('../client/pages/supplier/_psCode/_.vue' /* webpackChunkName: "pages/supplier/_psCode/_" */))
const _29d823e9 = () => interopDefault(import('../client/pages/cat/_slug/_.vue' /* webpackChunkName: "pages/cat/_slug/_" */))
const _7ec96b67 = () => interopDefault(import('../client/pages/_.vue' /* webpackChunkName: "pages/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/box/cart",
    component: _a13e2f6a,
    name: "box/cart"
  }, {
    path: "/quote/cart",
    component: _a13e2f6a,
    name: "quote/cart"
  }, {
    path: "/sample/cart",
    component: _a13e2f6a,
    name: "sample/cart"
  }, {
    path: "/proof/cart",
    component: _a13e2f6a,
    name: "proof/cart"
  }, {
    path: "/boxcart",
    component: _a13e2f6a,
    name: "boxcart"
  }, {
    path: "/quotecart",
    component: _a13e2f6a,
    name: "quotecart"
  }, {
    path: "/samplecart",
    component: _a13e2f6a,
    name: "samplecart"
  }, {
    path: "/proofcart",
    component: _a13e2f6a,
    name: "proofcart"
  }, {
    path: "/products/no-minimum",
    component: _c7543736,
    name: "products-no-minimum"
  }, {
    path: "/products/closeout",
    component: _f239b30e,
    name: "products-closeout"
  }, {
    path: "/account",
    component: _0932f4f8,
    name: "account"
  }, {
    path: "/cart",
    component: _a13e2f6a,
    name: "cart"
  }, {
    path: "/cartitemsadded",
    component: _5b5924ea,
    name: "cartitemsadded"
  }, {
    path: "/changepassword",
    component: _25d07db6,
    name: "changepassword"
  }, {
    path: "/customer-artwork-proof",
    component: _5f52c8a7,
    name: "customer-artwork-proof"
  }, {
    path: "/designs",
    component: _70c92740,
    name: "designs"
  }, {
    path: "/facets",
    component: _57a9ffc7,
    name: "facets"
  }, {
    path: "/favorites",
    component: _b8c426bc,
    name: "favorites"
  }, {
    path: "/forgotpassword",
    component: _01ed7669,
    name: "forgotpassword"
  }, {
    path: "/health",
    component: _6774c5f8,
    name: "health"
  }, {
    path: "/not-found",
    component: _d17cf5da,
    name: "not-found"
  }, {
    path: "/proof",
    component: _572dbf4f,
    name: "proof"
  }, {
    path: "/proofitemsadded",
    component: _de5b60e2,
    name: "proofitemsadded"
  }, {
    path: "/quoteitemsadded",
    component: _c5ea0e72,
    name: "quoteitemsadded"
  }, {
    path: "/resetpassword",
    component: _75594775,
    name: "resetpassword"
  }, {
    path: "/sampleitemsadded",
    component: _59e17b15,
    name: "sampleitemsadded"
  }, {
    path: "/signin",
    component: _47e65e4d,
    name: "signin"
  }, {
    path: "/signout",
    component: _22e9023c,
    name: "signout"
  }, {
    path: "/suppliers",
    component: _8516831c,
    name: "suppliers"
  }, {
    path: "/testy",
    component: _33d5c472,
    name: "testy"
  }, {
    path: "/version",
    component: _5a15ef60,
    name: "version"
  }, {
    path: "/version-app",
    component: _52813fb7,
    name: "version-app"
  }, {
    path: "/cat/GeneralProductView",
    component: _20ecdb4d,
    name: "cat-GeneralProductView"
  }, {
    path: "/orders/products",
    component: _ca7ff24e,
    name: "orders-products"
  }, {
    path: "/products/utils",
    component: _c0b0d03e,
    name: "products-utils"
  }, {
    path: "/products/search/mixin",
    component: _b8a1ec7c,
    name: "products-search-mixin"
  }, {
    path: "/products/search/search",
    component: _0b1a4e3a,
    name: "products-search-search"
  }, {
    path: "/sso/facebook/delete",
    component: _43eea99c,
    name: "sso-facebook-delete"
  }, {
    path: "/sso/punch-out/:source?",
    component: _d8deca3c,
    name: "sso-punch-out-source"
  }, {
    path: "/review/order/:orderId?/product/:productId?",
    component: _2cd2c4e0,
    name: "review-order-orderId-product-productId"
  }, {
    path: "/products/search/*",
    component: _115cc9ea,
    name: "products-search-all"
  }, {
    path: "/products/no-minimum/*",
    component: _c7543736,
    name: "products-no-minimum-all"
  }, {
    path: "/products/closeout/*",
    component: _f239b30e,
    name: "products-closeout-all"
  }, {
    path: "/blog/cat/*",
    component: _d173903e,
    name: "blog-cat-all"
  }, {
    path: "/approval/:orderId",
    component: _8389bcbe,
    name: "approval-orderId"
  }, {
    path: "/artproof/:productSlug",
    component: _7c788658,
    name: "artproof-productSlug"
  }, {
    path: "/authors/:authorName",
    component: _47238699,
    name: "authors-authorName"
  }, {
    path: "/components/:componentId",
    component: _3a28ec1d,
    name: "components-componentId"
  }, {
    path: "/configureproduct/:productSlug",
    component: _1fdfcd4c,
    name: "configureproduct-productSlug"
  }, {
    path: "/customer-artwork-proof/:id",
    component: _2e8d8fdc,
    name: "customer-artwork-proof-id"
  }, {
    path: "/designer/:productSlug",
    component: _668ceb6a,
    name: "designer-productSlug"
  }, {
    path: "/designs/:slug",
    component: _4930b27b,
    name: "designs-slug"
  }, {
    path: "/facets/:slug",
    component: _a10e6c7c,
    name: "facets-slug"
  }, {
    path: "/largequote/:productSlug",
    component: _c99c8d18,
    name: "largequote-productSlug"
  }, {
    path: "/orders/:id",
    component: _9661e68a,
    name: "orders-id"
  }, {
    path: "/payments/:orderId",
    component: _3ad92712,
    name: "payments-orderId"
  }, {
    path: "/presentation/:orderId",
    component: _dd1bc92c,
    name: "presentation-orderId"
  }, {
    path: "/presentations/:id",
    component: _766c6562,
    name: "presentations-id"
  }, {
    path: "/printinvoice/:orderId",
    component: _3948c4e4,
    name: "printinvoice-orderId"
  }, {
    path: "/products/:slug",
    component: _15c9058a,
    name: "products-slug"
  }, {
    path: "/proof/:id",
    component: _112eca8c,
    name: "proof-id"
  }, {
    path: "/quickquote/:productSlug",
    component: _25a9f5b4,
    name: "quickquote-productSlug"
  }, {
    path: "/reorder/:id",
    component: _a5d0ab9e,
    name: "reorder-id"
  }, {
    path: "/sample/:productSlug",
    component: _6dd9e1cb,
    name: "sample-productSlug"
  }, {
    path: "/sso/:service?",
    component: _78afb9c4,
    name: "sso-service"
  }, {
    path: "/cat/:slug?/brand",
    component: _69b31211,
    name: "cat-slug-brand"
  }, {
    path: "/orders/:id?/track-shipment",
    component: _9a73d4f4,
    name: "orders-id-track-shipment"
  }, {
    path: "/printinvoice/:orderId?/short",
    component: _1eed88e4,
    name: "printinvoice-orderId-short"
  }, {
    path: "/products/:slug?/reviews",
    component: _0898bc53,
    name: "products-slug-reviews"
  }, {
    path: "/facets/:slug/:facet",
    component: _51e49f7b,
    name: "facets-slug-facet"
  }, {
    path: "/supplier/:psCode?/*",
    component: _1f37ac24,
    name: "supplier-psCode-all"
  }, {
    path: "/cat/:slug?/*",
    component: _29d823e9,
    name: "cat-slug-all"
  }, {
    path: "/*",
    component: _7ec96b67,
    name: "all"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
